export const CAMPOS = {
    IDENTIFICADOR: 'identificador',
    CODIGO: 'codigo',
};

export const CONTEXTO = {
    ANALISE: {
        caminho: 'analise',
        titulo: 'análise',
    },
    EXAME: {
        caminho: 'exame',
        titulo: 'exames',
    },
    TCLE: {
        caminho: 'tcle',
        titulo: 'tcle',
    },
    APAC: {
        caminho: 'apac',
        titulo: 'processos de apac',
    },
    WEBCONFERENCIA: {
        DECLARACAO: {
            caminho: 'webconferencia/declaracao',
            titulo: 'declarações de webconferência',
        },
        CERTIFICADO: {
            caminho: 'webconferencia/certificado',
            titulo: 'certificados de webconferência',
        },
    },
    AVALIACAO_CLINICA: {
        caminho: 'oxigenoterapia/avaliacao-clinica',
        titulo: 'avaliações clínicas de oxigenoterapia'
    }
};
